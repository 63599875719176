import React from 'react';
import Notification from '../../api_connections/Notification';
import {
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBRow,
  MDBCol,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBInput,
  MDBBtn
} from 'mdbreact';
import { MDBContainer, MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarToggler, MDBCollapse, MDBNavItem, MDBNavLink, MDBIcon } from 'mdbreact';

import { BrowserRouter as Router } from 'react-router-dom';
import logo from '../../images/icon.png';

class FixedNavbarExample extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      modal: false,
      subject: '',
      isScheduled: false,
      trigger_schedule: '',
      expiration: '',
      description: '',
      collapse: false,
      loading: false,
    };
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.setState({
      collapse: !this.state.collapse,
    });
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  handleLogOut = () => {
    localStorage.removeItem('token');
    window.location.replace('/');
  }

  handleOnchange = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };

  componentDidMount = async () => {
    this.setState({ loading: true });
    let response = await new Notification().fetchAll();
    if (response.status === 200) {
      console.log(response, 'this is success');
      this.setState({ data: response.data.data, loading: false });
    } else {
      console.error(response, 'this is failed');
    }
  };

  handleLoadTableDtata = () => {
    return this.state.data.map((item) => {
      return (
        <tr key={item.id}>
          <td>{item.id}</td>
          <td>{item.subject}</td>
          <td>{item.content}</td>
          <td>{item.schedule}</td>
          <td>{item.expiration}</td>
        </tr>
      );
    })
  }

  onClick = async (e) => {
    e.preventDefault();
    if (!this.state.isScheduled) {
      await new Notification().create({
        subject: this.state.subject,
        content: this.state.description,
        status: "active",
        schedule: this.state.trigger_schedule,
        expiration: this.state.expiration,
        isScheduled: (this.state.isScheduled) ? 'true' : 'false'
      });
      window.location.reload();
    }
    else {
      if (this.state.expiration === '' && this.state.trigger_schedule === '') {
        alert('Schedule and Expiration are required!')
      }
      else {
        await new Notification().create({
          subject: this.state.subject,
          content: this.state.description,
          status: "active",
          schedule: this.state.trigger_schedule,
          expiration: this.state.expiration,
          isScheduled: (this.state.isScheduled) ? 'true' : 'false'
        });
        window.location.reload();
      }
    }
  };

  render() {
    const bgUnioil1 = { backgroundColor: '#005598' }
    const container = { height: 1300 }
    return (
      <div style={{ overflow: 'none' }}>
        <Router>
          <header>
            <MDBNavbar style={bgUnioil1} dark expand="md" scrolling fixed="top">
              <MDBNavbarBrand href="/">
                <img alt="" src={logo} height="35"></img>
              </MDBNavbarBrand>
              <MDBNavbarToggler onClick={this.onClick} />
              <MDBCollapse isOpen={this.state.collapse} navbar>
                <MDBNavbarNav left>
                  <MDBNavItem>
                    <MDBNavLink to="#">Home</MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem active>
                    <MDBNavLink to="#">Notifications</MDBNavLink>
                  </MDBNavItem>
                </MDBNavbarNav>
                <MDBNavbarNav right>
                  <MDBNavItem>
                    <MDBNavLink to="#" onClick={this.handleLogOut}><MDBIcon icon="power-off" /> Logout</MDBNavLink>
                  </MDBNavItem>
                </MDBNavbarNav>
              </MDBCollapse>
            </MDBNavbar>
          </header>
        </Router>
        <MDBContainer style={container} className="text-center mt-5 pt-5">
          <MDBBtn size='sm' onClick={this.toggle} className='float-right' color='primary'>
            New Notification
        </MDBBtn>
          <MDBModal isOpen={this.state.modal} toggle={this.toggle} backdrop={false}>
            <MDBModalHeader toggle={this.toggle}>New Notification</MDBModalHeader>
            <MDBModalBody>
              <MDBContainer>
                <MDBRow>
                  <MDBCol md='12'>
                    <form>
                      <label htmlFor='defaultFormRegisterNameEx' className='grey-text' style={{ float: 'left' }}>
                        Subject
                    </label>
                      <input
                        type='text'
                        name='subject'
                        className='form-control'
                        onChange={this.handleOnchange}
                        value={this.state.subject}
                      />
                      <br />
                      <label htmlFor='defaultFormRegisterNameEx' className='grey-text' style={{ float: 'left' }}>
                        Description
                    </label>
                      <textarea
                        className='form-control'
                        name='description'
                        onChange={this.handleOnchange}
                        value={this.state.description}
                      />
                      <br></br>
                      <div>
                        <MDBInput label="Schedule Notification" checked={this.state.isScheduled} type="checkbox" id="checkbox2"
                          onChange={() => this.setState({
                            isScheduled: !this.state.isScheduled
                          })} 
                        />
                      </div>
                      <br></br>
                      {this.state.isScheduled &&
                        <div>
                          <label htmlFor='defaultFormRegisterNameEx' className='grey-text' style={{ float: 'left' }}>
                            Schedule
                      </label>
                          <input
                            type='datetime-local'
                            name='trigger_schedule'
                            className='form-control'
                            onChange={this.handleOnchange}
                            value={this.state.trigger_schedule}
                          />
                          <br></br>
                          <label htmlFor='defaultFormRegisterNameEx' className='grey-text' style={{ float: 'left' }}>
                            Expiration
                        </label>
                          <input
                            type='datetime-local'
                            name='expiration'
                            className='form-control'
                            onChange={this.handleOnchange}
                            value={this.state.expiration}
                          />
                        </div>
                      }
                      <br />
                      <div className='text-center mt-4'>
                        <MDBBtn color='primary' size='md' type='submit' onClick={this.onClick}>
                          Submit
                      </MDBBtn>
                      </div>
                    </form>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </MDBModalBody>
            <MDBModalFooter />
          </MDBModal>
          <div style={{ height: '75vh', width: '100%', overflow: 'auto', overflowY: 'scroll' }}>

          {this.state.loading ? <div style={{ position: 'absolute', marginTop: '10%', marginLeft: '37%' }}><div className="spinner-grow text-primary text-center" role="status">
                  <span className="sr-only">Loading...</span>
                </div></div> 
                : 
              <MDBTable responsive hover striped style={{ height: '100px' }}>
              <MDBTableHead>
                <tr>
                  <th>#</th>
                  <th>Subject</th>
                  <th>Description</th>
                  <th>Schedule</th>
                  <th>Expiration</th>
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {this.handleLoadTableDtata()}
              </MDBTableBody>
            </MDBTable>}
          </div>
        </MDBContainer>
      </div>
    );
  }
}

export default FixedNavbarExample;