import Base from './Base';
import api from '../constants/api';

export default class User extends Base {
  fetchAll = async () => {
    return await this.sendSecureRequest(api.Methods.GET, api.NOTIFICATIONS);
  };

  create = async (data) => {
    return await this.sendSecureRequest(api.Methods.POST, api.NOTIFICATIONS, data);
  };

  sendMail = async (data) => {
    return await this.sendRequest(api.Methods.POST, 'user/sendMail', data);
  };

  resetPassword = async (data) => {
    return await this.sendRequest(api.Methods.POST, 'user/resetPassword', data);
  }

}