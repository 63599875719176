import axios from 'axios';
import api from '../constants/api';

export default class Base {
  constructor() {
    console.log('New connection has been initialized');
  }

  sendRequest = async (method, url, data = {}) => {
    const config = {
      method,
      url: `${api.BASE_URL}${url}`,
      data,
    };
    console.log(config);
    const response = await axios(config).catch((err) => {
      return { status: 503, message: 'Network error', err };
    });
    return response;
  };

  sendSecureRequest = async (method, url, data = {}) => {
    const config = {
      method,
      url: `${api.BASE_URL}${url}`,
      headers: { Authorization: `Bearer ${window.localStorage.getItem('token')}` },
      data,
    };
    const response = await axios(config).catch((err) => {
      return err.response;
    });
    return response;
  };
}
