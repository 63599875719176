import Base from './Base';
import api from '../constants/api';

export default class Notification extends Base {
  fetchAll = async () => {
    return await this.sendSecureRequest(api.Methods.GET, api.NOTIFICATIONS);
  };

  create = async (data) => {
    return await this.sendSecureRequest(api.Methods.POST, api.NOTIFICATIONS, data);
  };

  getAllScheduled = async () => {
    return await this.sendRequest(api.Methods.GET, api.NOTIFICATIONS+'/all/scheduled');
  };

  pushNotif = async (data) => {
    return await this.sendRequest(api.Methods.POST, api.NOTIFICATIONS+'/push', data);
  };
}
