export default {
  Methods: {
    POST: 'post',
    GET: 'get',
    PUT: 'put',
    PATCH: 'patch',
    DELETE: 'delete',
  },
  BASE_URL: 'https://notification.unioilapps.com/api/',
  NOTIFICATIONS: 'notification',
  FCMTOKEN: 'fcmtoken',
  LOGIN: 'user/auth',
};
