import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Login from '../login/index';
import Notification from '../notification/index';
import React, { Component } from 'react';
import NotificationAPI from '../../api_connections/Notification';
import cron from 'node-schedule';
import moment from 'moment';
export class index extends Component {
  state = {
    data: []
  }
  handleLogin = () => {
    if (window.localStorage.getItem('token') === null && window.location.pathname != '/login') {
      window.location.replace('/login');
    }
  };

  UNSAFE_componentWillMount(){
    cron.scheduleJob('*/5 * * * * *', () => {
      this.handleGetScheduled();
    });
  }

  handleGetScheduled = async () => {
    let response = await new NotificationAPI().getAllScheduled();
    this.setState({data: response.data})
    if(this.state.data != undefined && this.state.data.length > 0){
      this.state.data.map( e => {
        if(moment().format('YYYY-MM-DDTHH:mm') >= e.schedule ){
          console.log(e)
          this.pushNotif({
            subject: e.subject,
            content: e.content,
            notif_id: e.id
          })
        }
      })
    }
  }

  pushNotif = async (data) => {
    let response = await new NotificationAPI().pushNotif(data);
    console.log(response)

  }

  render() {
    return (
      <Router>
        <Switch>
          <Redirect exact from='/' to='/login' />
          <Route path='/login'>
            <Login />
          </Route>
          {this.handleLogin()}
          <Route path='/notification'>
            <Notification />
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default index;
