import React, { Component } from 'react';
import Auth from '../../api_connections/Auth';
import User from '../../api_connections/User';
import logo from '../../images/unioil.png';

export class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      modal: false,
      vcode: '',
      enteredcode: '',
      newpass: '',
      loading: false,
    };
  }

  toggleModal = () => {
    if (this.state.username) {
      if (this.state.modal == false) {
        this.SendMail();
      }
      this.setState({ modal: !this.state.modal });
    }
  };

  SendMail = async () => {
    let response = await new User().sendMail({ email: this.state.username });
    if ((response.status = 'success')) {
      this.setState({ vcode: response.data.code });
      console.log('Email Sent!', response);
      console.log(this.state.vcode);
    }
  };

  handleResetPassword = async () => {
    let response = await new User().resetPassword({
      email: this.state.username,
      pw: this.state.newpass,
      code: this.state.vcode,
      enteredCode: this.state.enteredcode,
    });
    console.log(response);
    if (response.data.error) {
      alert(response.data.error);
    } else if (response.data.status == 'updated') {
      alert('Your password has been updated successfully. Please login to continue');
      window.location.reload();
    }
  };

  handleLogin = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    let response = await new Auth().login({
      email: this.state.username,
      pw: this.state.password,
    });
    console.log(response);
    if (response.data != undefined && response.data.token) {
      alert('login success');
      this.setState({ loading: false });
      window.localStorage.setItem('token', response.data.token);
      window.location.replace('/notification');
    } else if (response.data != undefined && response.data.status != 'success_login') {
      this.setState({ loading: false });
      alert('Invalid username or password');
    } else {
      this.setState({ loading: false });
      alert('Network error');
    }
  };

  handleOnchange = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };

  render() {
    return (
      <main className="bodymain d-flex align-items-center min-vh-100 py-3 py-md-0">
      <div className="container">
      <div className="card login-card">
        <div className="row no-gutters">
          <div className="col-md-6">
            <img src="https://cdn2.hubspot.net/hubfs/2387885/increase-gas-station-sales.jpeg" alt="login" className="login-card-img" />
          </div>
          <div className="col-md-6">
            <div className="card-body">
              <div className="brand-wrapper">
                <img src={logo} alt="logo" className="logo"/>
              </div>
              <p className="login-card-description">Sign into your account</p>
              <form action="#!">
                  <div className="form-group">
                    <label htmlFor="email" className="sr-only">Email</label>
                    <input type="email" value={this.state.username}
                      name='username'
                      onChange={this.handleOnchange}
                      id="email" className="form-control" placeholder="Username"/>
                  </div>
                  <div className="form-group mb-4">
                    <label htmlFor="password" className="sr-only">Password</label>
                    <input value={this.state.password} onChange={this.handleOnchange} type="password" name="password" id="password" className="form-control" placeholder="***********"/>
                  </div>
                  {this.state.loading ? <div classNameName="spinner-border  text-danger" role="status">
                    <span classNameName="sr-only">Loading...</span>
                  </div> : <input onClick={this.handleLogin} name="login" id="login" className="btn btn-block login-btn mb-4" type="button" value="Login"/>}
                  
                </form>
                
            </div>
          </div>
        </div>
      </div>
      </div>
      </main>
    );
  }
}

export default index;
